import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  CircularProgress
} from "@material-ui/core";
import { Link } from "react-router-dom";

// styles
import useStyles from "../../../styles/dashboard/styles";

// components
import Widget from "../../../components/Widget";
import PageTitle from "../../../components/PageTitle";
import { Typography } from "../../../components/Wrappers";
import Divider from '@material-ui/core/Divider';
import Table from "./components/Table/Table";
import TableReclamos from "./components/Table/TableReclamos";
import Notification from "../../../components/Notification";

import isNullOrWhitespace from "../../../utiles/validaciones";

import Profile from "../ciudadano/Profile";

import gestorAxios from '../../../config/axios';

import { useUserState } from "../../../context/UserContext";

export default function Dashboard(props) {
  var classes = useStyles();

  var { token, userLogueadoId, datosCargadosOK } = useUserState();

  const [faltanDatosPersonales, setFaltanDatosPersonales] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [turnosSolicitados, setTurnosSolicitados] = useState({});
  const [reclamosSolicitados, setReclamosSolicitados] = useState({});
  const [datosUsuario, setDatosUsuario] = useState({
    email: "",
    nombre: "",
    apellido: "",
    telefono: "",
    domicilioLocal: "",
    barrioId: 0,
    domicilioResidencia: "",
    provinciaId: 0,
    id: 0
  });

  useEffect(() => {
    //console.log("EN USEEFFECT", datosUsuario, cargando);
    if (!cargando) {

      //console.log("ENTRA A CARGAR", token, userLogueadoId);
      setCargando(true);

      gestorAxios.get(`/turno/turno/filtrar/CiudadanoId/${userLogueadoId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {

        //console.log("recibe datos", res.data);
        setTurnosSolicitados(res.data);
      });


      gestorAxios.get(`/reclamo/reclamo/filtrar/CiudadanoId/${userLogueadoId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {

        console.log("recibe RECLAMOS", res.data);
        setReclamosSolicitados(res.data);
      });



      gestorAxios.post('/ciudadano/verifyToken', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {

        console.log("*** recibe datos", res.data);



        setDatosUsuario({
          email: res.data.data.email,
          nombre: res.data.data.nombre,
          apellido: res.data.data.apellido,
          telefono: res.data.data.telefono,
          domicilioLocal: res.data.data.domicilioLocal,
          BarrioId: res.data.data.BarrioId,
          domicilioResidencia: res.data.data.domicilioResidencia,
          provinciaId: res.data.data.provinciaId,
          fechaNac: res.data.data.fechaNac,
          id: res.data.data.id
        })

     
        // //console.log("********* VALIDA:", isNullOrWhitespace (res.data.data.barrioId ));

        if (isNullOrWhitespace(res.data.data.barrioId) || isNullOrWhitespace(res.data.data.nombre) || isNullOrWhitespace(res.data.data.apellido) ||
          isNullOrWhitespace(res.data.data.domicilioLocal) || isNullOrWhitespace(res.data.data.telefono) || isNullOrWhitespace(res.data.data.fechaNac))  {
          //console.log("detecta que faltan datos");
          setFaltanDatosPersonales(true);
        }

      });


    }

  });

  const contactar = () => {
    window.location.assign('https://www.municipiosantarosa.gob.ar/informacion-util/');
  }

  const onCancelTurno = () => {

    setCargando(false);

  }

  const datosActualizados = () => {
    setFaltanDatosPersonales(false);
  }

  if (faltanDatosPersonales) {

    if (datosUsuario.email === null) {
      return (
        <div>
          <CircularProgress size={26} />
        </div>);

    } else {

      return (
        <>
          <Grid item xs={12} md={12} lg={12}>
            <Widget title="IMPORTANTE" disableWidgetMenu>
              <Notification
                className={classes.notificationItem}
                shadowless
                type="defence"
                message="Completa tus datos personales antes de comenzar a utiliar la APP"
                variant="contained"
                color="secondary"
              />

            </Widget>
          </Grid>

          <Divider className={classes.separador} variant="middle" />

          <Profile datos={datosUsuario} token={token} onUpdate={datosActualizados}></Profile>
        </>
      );
    }
  } else {

    return (
      <>
        <PageTitle title="Mesa de entrada" button={
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={contactar}
          >
            INFO ÚTIL / CONTACTO
      </Button>} />
        <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              disableWidgetMenu={true}
              title="Turnos"
              upperTitle
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Grid container item alignItems={"center"}>
                  <Grid item xs={8}>
                    <Typography variant="h5" weight="medium" noWrap>
                      Mis turnos
                </Typography>
                  </Grid>
                  <Grid className={classes.centrado} item xs={4}>
                    <Typography size="xl" weight="medium" noWrap>
                      {turnosSolicitados.length > 0 ?
                        <b>
                          {turnosSolicitados.filter(item => item.cancelado !== true).length}
                        </b>
                        :
                        <>0</>
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  {turnosSolicitados.length > 0 ?
                    <div>
                      {turnosSolicitados.filter(item => item.cancelado !== true).length > 3 ?

                        <div> Has llegado al máximo de turnos</div>
                        :
                        <Link className={classes.linkSinSubrayado} to={'/app/nuevoturno/'}>
                          <Button

                            size="medium"
                            color="primary"
                          >
                            SOLICITAR TURNO
      </Button></Link>
                      }
                    </div>
                    :
                    <Link className={classes.linkSinSubrayado} to={'/app/nuevoturno/'}>
                      <Button

                        size="medium"
                        color="primary"
                      >
                        SOLICITAR TURNO
      </Button></Link>
                  }
                </Grid>

              </Grid>
            </Widget>
          </Grid>





          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              disableWidgetMenu={true}
              title="RECLAMOS"
              upperTitle
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Grid container item alignItems={"center"}>
                  <Grid item xs={8}>
                    <Typography variant="h5" weight="medium" noWrap>
                      En curso
                </Typography>
                  </Grid>
                  <Grid className={classes.centrado} item xs={4}>
                    <Typography size="xl" weight="medium" noWrap>
                      {reclamosSolicitados.length > 0 ?
                        <b>
                          {reclamosSolicitados.filter(item => item.resuelto !== true).length}
                        </b>
                        :
                        <>0</>
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>

                  <Link className={classes.linkSinSubrayado} to={'/app/nuevoreclamo/'}>
                    <Button

                      size="medium"
                      color="primary"
                    >
                      INICIAR UN RECLAMO
                    </Button>
                  </Link>

                </Grid>

              </Grid>
            </Widget>
          </Grid>





          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Widget
              disableWidgetMenu={true}
              title="Accesos"
              upperTitle
              className={classes.card}
              bodyClass={classes.fullHeightBody}
            >
              <div className={classes.centrado}>
                <Grid container item alignItems={"center"}>
                  <Grid item xs={12}>

                    <a style={{ width: '100%' }} href={'https://www.municipiosantarosa.gob.ar/noticias/'}>
                      <Button
                        fullWidth

                        color="primary"
                      >
                        VER NOTICIAS
      </Button></a>


                    <a style={{ width: '100%' }} href={'https://www.municipalidad.com/srca/home/menu'}>
                      <Button

                        fullWidth
                        color="primary"
                      >
                        PAGOS ONLINE
      </Button></a>


                  </Grid>
                </Grid>
              </div>



            </Widget>

          </Grid>






          <Grid item xs={12}>

            <Widget
              disableWidgetMenu={true}
              title="Mis próximos turnos"
              upperTitle
              noBodyPadding
              bodyClass={classes.tableWidget}

            >

              <div style={{ marginTop: 15 }}>
                <Table onCancelTurno={onCancelTurno} data={turnosSolicitados} />
              </div>
            </Widget>
          </Grid>



          <Grid item xs={12}>

            <Widget
              disableWidgetMenu={true}
              title="Mis reclamos"
              upperTitle
              noBodyPadding
              bodyClass={classes.tableWidget}

            >

              <div style={{ marginTop: 15 }}>
                <TableReclamos onCancelTurno={onCancelTurno} data={reclamosSolicitados} />
              </div>
            </Widget>
          </Grid>


        </Grid>
      </>
    );

  }



}
