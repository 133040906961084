import React from "react";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";


// styles
import useStyles from "../../styles/pages/error/styles";

// logo
import logo from "../login/Escudo-horizontal_baja.png";

export default function Error() {
  var classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
      <img src={logo} alt="logo" className={classes.logotypeImage} />
        
      </div>
      <Paper classes={{ root: classes.paperRoot }}>
      <div className={classes.logoMBContainer}>
        <img src={logo} alt="logo" className={classes.logoMBtypeImage} />
        </div>
        <Typography
          variant="h1"
          color="primary"
          className={classes.textRow, classes.errorCode}
        >
          404
        </Typography>
        <Typography variant="h5" color="primary" className={classes.textRow}>
          Error en el acceso a la pagina solicitada.
        </Typography>
         
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/login"
          size="large"
          className={classes.backButton}
        >
          Volver
        </Button>
      </Paper>
    </Grid>
  );
}
