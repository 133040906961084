import React from "react";


import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import SettingsIcon from '@material-ui/icons/Settings';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import './styles.css';

import useStyles from "../../../../../styles/dashboard/styles";

import gestorAxios from '../../../../../config/axios';

import { useUserState } from "../../../../../context/UserContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TableComponent({ data, onCancelTurno }) {

  const classes = useStyles();
  // console.log("DATAAAA: ", data);

  var { token, userLogueadoId } = useUserState();

  const keys = ['Fecha inicio', 'Información del reclamo', 'Estado',];

  const [open, setOpen] = React.useState(false);
  const [datosCancelaTurno, setDatosCancelaTurno] = React.useState({});
  const [textoCancelaTurno, setTextoCancelaTurno] = React.useState("");
 



  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseElimina = () => {

    // Cancelacion del turno
    // console.log("CANCELA CON: ", userLogueadoId, datosCancelaTurno.id, token);




    gestorAxios.put(`/turno/turno/cancelar/${userLogueadoId}/${datosCancelaTurno.id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {

      setDatosCancelaTurno({});
      setTextoCancelaTurno("");

      onCancelTurno();

    });

    setOpen(false);
  }


  const convertirFecha = (fechaTurno) => {

    return (<div key={fechaTurno} >{convertirFechaToStr(fechaTurno)}</div>);

  }

  const convertirFechaToStr = (fechaTurno) => {

    const texto = fechaTurno.substr(8, 2) + "/" + fechaTurno.substr(5, 2) + "/" + fechaTurno.substr(0, 4);
    return texto;
  }






  if (data.length > 0) {

    // console.log("datosCancelaTurno: ", datosCancelaTurno)

    return (
      <>


        <Table >
          <Thead >
            <Tr  >
              {keys.map(item => <Th key={item}>{item}</Th>)}
            </Tr>
          </Thead>
          <Tbody>

            {data.map(({ id, Area, PuntoInicioReclamo,Usuario, SubAreaReclamo, EstadosReclamo, updatedAt, fechaInicio, motivo, resuelto }) => (

              <Tr key={id}>
                <Td style={{ color: "black", backgroundColor: "lightgreen", textAlign: "center", padding: 10 }} className="pl-3 fw-normal"> {convertirFecha(fechaInicio)}</Td>
                <Td style={{ color: "black", backgroundColor: "#e3f2fd", padding: 10 }}>

                  <b> Iniciado en:  {PuntoInicioReclamo.nombre} </b><br />
                  <b> Atendido por:  {Usuario.nombre} {Usuario.apellido}</b><br /><br />

                  <b>Actualmente en: {Area.nombreArea.toUpperCase()} [tel: {Area.telefono}]</b>    <br />
                  <b>Tipo Reclamo: {SubAreaReclamo.nombreSubArea.toUpperCase()}  </b>    <br /><br />


                  <b> Última actualización:  {convertirFechaToStr(updatedAt)} </b> <br />



                  <b>Motivo: </b> {motivo}

                </Td>


                <Td style={{ color: "black", backgroundColor: "#e3f2fd", textAlign: "center", padding: 10 }}>

                  {resuelto ?

                    <> <Chip color="secondary" style={{ backgroundColor: "green", color: "white" }} icon={<CheckCircleIcon />} size="small" label="RESUELTO" /> </>
                    :
                    <>{EstadosReclamo ?
                      <>
                      <Chip color="secondary" style={{ backgroundColor: "blue", color: "white" }} icon={<SettingsIcon />} size="small" label={EstadosReclamo.nombre} />
                      </>
                      :
                      <><Chip color="secondary" style={{ backgroundColor: "red", color: "white" }} icon={<CancelIcon />} size="small" label="NO INICIADO" /></>
                    }</>
                  }
                </Td>


              </Tr>


            ))}



          </Tbody>
        </Table>



        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Desea cancelar su turno?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              DATOS DEL TURNO:<br />
              {textoCancelaTurno}

            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: 20 }}>
            <Button onClick={handleClose} color="primary">
              No cancelar
          </Button>
            <Button variant="contained" onClick={handleCloseElimina} color="secondary">
              Si quiero cancelar mi turno
          </Button>
          </DialogActions>
        </Dialog>

      </>
    )
  } else {
    return (
      <> <p style={{ margin: 20 }}> No hay turnos reservados</p></>
    )
  }

}