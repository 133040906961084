import React from "react";
import {
  Route,
  Switch,

  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiYoutube as YoutubeIcon,
  mdiInstagram as InstagramIcon,
} from '@mdi/js'

// styles
import useStyles from "../../../styles/components/Layout/styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar/Sidebar";

// pages
import Dashboard from "../../pages/dashboard/Dashboard";

// context
import { useLayoutState } from "../../../context/LayoutContext";
import Perfil from "../../pages/ciudadano/EditProfile";
import Nuevoturno from "../../pages/ciudadano/NuevoTurno";
import NuevoReclamo from "../../pages/ciudadano/NuevoReclamo";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/perfil" component={Perfil} />
              <Route path="/app/nuevoturno" component={Nuevoturno} />            
              <Route path="/app/nuevoreclamo" component={NuevoReclamo} />            
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>
                <Link
                  color={'primary'}
                  href={'https://www.municipiosantarosa.gob.ar/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Copyrigth @2020 - Municipalidad de Santa Rosa de Calamuchita
                </Link>
                 </div>
              <div>
                <Link
                  href={'https://www.facebook.com/Municipalidadsantarosa/'}
                  target={'_blank'}
                >
                  <IconButton aria-label="facebook">
                    <Icon
                      path={FacebookIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://twitter.com/santarosamuni?lang=es'}
                  target={'_blank'}
                >
                  <IconButton aria-label="twitter">
                    <Icon
                      path={TwitterIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://www.instagram.com/santarosamuni/?hl=es'}
                  target={'_blank'}
                >
                  <IconButton
                    aria-label="github"
                    
                  >
                    <Icon
                      path={InstagramIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://www.youtube.com/channel/UCd-95nRMjUKz_bzJAeFkm7w'}
                  target={'_blank'}
                >
                  <IconButton
                    aria-label="github"
                    style={{marginRight: -12}}
                  >
                    <Icon
                      path={YoutubeIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
              </div>
            </Box>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
