import React, { useState, useEffect } from "react";


// components
import Grid from '@material-ui/core/Grid';

import { CircularProgress } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import Widget from "../../../components/Widget/Widget";

import TurnoForm from "../ciudadano/TurnoForm";
import gestorAxios from '../../../config/axios';
import { useUserState } from "../../../context/UserContext";

export default function NuevoTurno(props) {

  var { token, userLogueadoId } = useUserState();

  const [datosDB, setDatosDB] = useState({});
  const [cargando, setCargando] = useState(false);
  const [maxNumTurnos, setMaxNumTurnos] = useState(false);


  useEffect(() => {

    if (!cargando) {

      //console.log("ENTRA A CARGAR", token);
      setCargando(true);

      gestorAxios.get(`/turno/turno/filtrar/CiudadanoId/${userLogueadoId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {

        //console.log("recibe datos", res.data);

        if (res.data.length > 0) {
          if (res.data.filter(item => item.cancelado !== true).length > 3) {
            setMaxNumTurnos(true);
          }

        }

      });

      gestorAxios.get('/turno/turno/ocupacion', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {

        console.log("recibe datos", res.data);

        setDatosDB(res.data)

        //console.log("********* LLEGA:", (res.data.areas));




      });


    }

  }, [cargando, token]);





  const datosActualizados = () => {
    props.history.push('/app/dashboard');
  }

  if (datosDB.areas) {

    if (datosDB.areas.length === 0) {
      return (
        <div>
          <CircularProgress size={26} />
        </div>);

    } else {

      return (
        <>

          { maxNumTurnos ?
            <div>

              <Grid container item xs={12} md={12} lg={12}>
                <Widget disableWidgetMenu>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h6" gutterBottom>
                        Haz llegado a la cantidad máximas de turnos por persona. <br />
                        Debes cancelar alguno de tus turnos, o esperar a realizar los que tienes pendientes.
         </Typography>
                    </Grid>
                  </Grid>
                </Widget>
              </Grid>
            </div>
            :
            <TurnoForm onUpdate={datosActualizados} token={token} userId={userLogueadoId} datos={datosDB}></TurnoForm>
          }


        </>
      );
    }
  } else {
    return (
      <div>
        <CircularProgress size={26} />
      </div>);
  }


}
