import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// components
import Layout_app from "./app/components/Layout/Layout";

// pages
import Error from "./pages/error/Error";
//import Inicio from "./pages/inicio/Inicio";
//import InicioAdmin from "./pages/inicio/InicioAdmin";
import Login from "./pages/login/Login";
//import LoginAdmin from "./pages/login/LoginAdmin"

// context
import { useUserState } from "./context/UserContext";

export default function App() {
  // global
  var { isAuthenticated, isAdmin } = useUserState();
  const accesoAdmin = isAdmin;

  ////console.log("isAdmin", isAdmin)

  return (
    <HashRouter>

      <Switch>
        <Route exact path="/" component={Login} />  
      
        {/* <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} /> */}
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        <PrivateRoute path="/app" component={Layout_app} />
        <PublicRoute path="/login" component={Login} />
        <Route component={Error} />
      </Switch>

    </HashRouter>

  );


  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    from: props.location,
                  },
                }}
              />
            )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
              React.createElement(component, props)
            )
        }
      />
    );
  }
}
