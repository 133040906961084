import React, { useState, useEffect } from "react";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import moment from 'moment';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,

    KeyboardDatePicker,
} from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";

import MenuItem from '@material-ui/core/MenuItem';

import gestorAxios from '../../../config/axios';

import useStyles from "../../../styles/globalStyleApp";
import Widget from "../../../components/Widget/Widget";

import ReactHookFormSelect from "../../../components/ReactHookFormSelect";

import { useForm } from 'react-hook-form'

export default function Profile(props) {

    console.log("DATA: ", props.datos);

    const [confirmaDatos, setConfirmaDatos] = useState(false);
    const [cambiaPassword, setCambiaPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [selectedDate, setSelectedDate] = React.useState(undefined);

    var classes = useStyles();
    const { register, handleSubmit, control, errors } = useForm();

    const [loading, setLoading] = useState(false);
    const [barrios, setBarrios] = useState({});
    const [provincias, setProvincias] = useState({});


    const handleDateChange = (date) => {
        setSelectedDate(date);  
        console.log("fecha:", date); 
    };
    console.log("** 1 setea fecha")
    if(!selectedDate && props.datos)
    { console.log("** 2 setea fecha")
        if(props.datos.fechaNac)
        {
            console.log("** 3 setea fecha")
            setSelectedDate( moment( props.datos.fechaNac ));
        }
    }



    useEffect(() => {

        if (!loading) {

            // //console.log("ENTRA A CARGAR", props.token);
            setLoading(true);


            gestorAxios.get('/barrio', {
                headers: {
                    Authorization: `Bearer ${props.token}`
                }
            }).then((res) => {

                //  //console.log("BARRIOS: ", res.data, props.datos);

                setBarrios(res.data)

            });

            gestorAxios.get('/provincia', {
                headers: {
                    Authorization: `Bearer ${props.token}`
                }
            }).then((res) => {

                //   //console.log("PROVINCIAS: ", res.data, props.datos);

                setProvincias(res.data)

            });


        }
        
    }, [loading, props.token, password, password2]);

    const actualizarDatos = data => {
        //console.log("A GUARDAR ANTES: ", data);
        if (!cambiaPassword || (cambiaPassword && (password === password2) && password.length > 4)) {

            if (cambiaPassword) {
                data.cambiaPassword = true;
                data.password = password;
            } else {
                data.cambiaPassword = false;
            }

            if (data.provinciaId === '') {
                data.provinciaId = 0
            }
            //console.log("A GUARDAR DESPUES: ", data);
            if(!data.BarrioId)
            {
                alert("Seleccione el barrio para poder guardar sus datos personales.");
                return false;
            }
            if(!selectedDate)
            {
                alert("Seleccione su fecha de nacimiento.");
                return false;
            }

            data.fechaNac = selectedDate;

            gestorAxios.put(`/ciudadano/actualizar/${props.datos.id}`, data, {
                headers: {
                    Authorization: `Bearer ${props.token}`
                }
            }).then((res) => {

                props.onUpdate();
                //console.log("ACTUALIZADO: ", res);

            });
        } else {
            alert("La contraseña deber tener mínimo 5 caracteres y tiene que ser ingresada correctamente en los dos casilleros");
        }

    }


    return (
        <React.Fragment>
            <Grid container item xs={12} md={12} lg={12}>
                <Widget disableWidgetMenu>
                    <Typography variant="h6" gutterBottom>
                        Datos Personales
      </Typography>
                    <form className={classes.form} noValidate onSubmit={handleSubmit((data) => {
                        actualizarDatos(data);
                    })}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField

                                    defaultValue={props.datos.nombre}
                                    variant="filled"
                                    error={!!errors.nombre}
                                    inputRef={register({ required: true })}
                                    id="nombre"
                                    name="nombre"
                                    label="Nombre"
                                    fullWidth
                                    autoComplete="given-name"

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField

                                    defaultValue={props.datos.apellido}
                                    variant="filled"
                                    error={!!errors.apellido}
                                    inputRef={register({ required: true })}
                                    id="apellido"
                                    name="apellido"
                                    label="Apellido"
                                    fullWidth
                                    autoComplete="family-name"
                                />
                            </Grid>



                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>

<Grid item xs={6} style={ !selectedDate ? { marginTop: 10,  backgroundColor: '#FF8888', padding: 10, borderRadius: 7} : { marginTop: 10}} container justify="space-around">

    <KeyboardDatePicker
        fullWidth

        margin="normal"
        id="date-picker-dialog"
        disableFuture
        format="dd/MM/yyyy"
        value={selectedDate}
        onChange={handleDateChange}
        helperText="Fecha de nacimiento"
        error={!!errors.fechaNac}
    />

</Grid>
</MuiPickersUtilsProvider>
<Grid item xs={6}>
</Grid>

                            <Grid item xs={12}>
                                <TextField
                                    defaultValue={props.datos.domicilioLocal}
                                    variant="filled"
                                    error={!!errors.domicilioLocal}
                                    inputRef={register({ required: true })}
                                    id="domicilioLocal"
                                    name="domicilioLocal"
                                    label="Dirección en Sta Rosa de Calamuchita"
                                    fullWidth
                                    autoComplete="shipping address-line1"
                                />
                            </Grid>


                            <Grid item xs={12} sm={6}>

                                <ReactHookFormSelect
                                    id="BarrioId"
                                    name="BarrioId"
                                    inputRef={register({ required: true })}
                                    error={!!errors.BarrioId}
                                    fullWidth
                                    label="Seleccione el barrio"
                                    control={control}
                                    defaultValue={props.datos.BarrioId || ''}
                                    variant="filled"

                                >

                                    {barrios.length > 0 ? barrios.map(item =>

                                        <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>

                                    ) : <div></div>}


                                </ReactHookFormSelect>


                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    defaultValue={props.datos.telefono}
                                    variant="filled"
                                    error={!!errors.telefono}
                                    id="telefono" inputRef={register({ required: true })} name="telefono" label="Telefono" fullWidth />
                            </Grid>

                            <Grid item xs={12}  >
                                Completar solamente si vive en otra localidad o provincia y su dirección en Santa Rosa es un domicilio no permanente.

                            </Grid>


                            <Grid item xs={12} sm={6}>

                                <ReactHookFormSelect
                                    id="provinciaId"
                                    name="provinciaId"
                                    inputRef={register({ required: true })}
                                    fullWidth
                                    label="Seleccione la provincia"
                                    control={control}
                                    defaultValue={props.datos.provinciaId || ''}
                                    variant="filled"

                                >

                                    {provincias.length > 0 ? provincias.map(item =>

                                        <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>

                                    ) : <div></div>}


                                </ReactHookFormSelect>


                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="filled"
                                    inputRef={register}
                                    defaultValue={props.datos.domicilioResidencia || ''}
                                    id="domicilioResidencia"
                                    name="domicilioResidencia"
                                    label="Domicilio de residencia"
                                    helperText="(localidad y dirección)"
                                    fullWidth
                                    autoComplete="shipping address-line2"
                                />
                            </Grid>


                            <Grid item sm={6} xs={12}>
                                <FormControlLabel
                                    control={<Checkbox color="secondary" value={cambiaPassword} onChange={() => { setCambiaPassword(!cambiaPassword) }} name="cambiaPassword" />}
                                    label="Cambia contraseña ?"
                                />


                            </Grid>

                            {cambiaPassword &&
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}> {password !== password2 &&
                                        <div style={{ backgroundColor: "red", color: "white", padding: 10, borderRadius: 10 }}>
                                            Complete la misma contraseña dos veces para asegurar que fué ingresada sin error de tipeo. Es una buena práctica usar minusculas, mayusculas y números.  MÍNIMO 5 CARACTERES.
                                </div>
                                    } </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField

                                            defaultValue={password}
                                            variant="filled"

                                            onChange={(e) => { setPassword(e.target.value) }}
                                            id="password"
                                            name="password"
                                            label="Contraseña"
                                            fullWidth
                                            type="password"
                                            autoComplete="given-name"

                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField

                                            defaultValue={password2}
                                            variant="filled"

                                            onChange={(e) => { setPassword2(e.target.value) }}
                                            id="password2"
                                            name="password2"
                                            label="Repita la contraseña"
                                            fullWidth
                                            type="password"
                                            autoComplete="family-name"
                                        />

                                    </Grid>

                                </Grid>
                            }


                            <Grid item sm={12} xs={12}>
                                <FormControlLabel
                                    control={<Checkbox color="secondary" value={confirmaDatos} onChange={() => { setConfirmaDatos(!confirmaDatos) }} name="confirma" />}
                                    label="Confirmo la veracidad de los datos ingresados."
                                />


                            </Grid>
                            <Grid item sm={6} xs={12}
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"

                            >
                                <Button
                                    disabled={!confirmaDatos}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    GUARDAR
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Widget>

            </Grid>
        </React.Fragment>
    );

}