import axios from 'axios';

 // Setting para uso local



let GestorAxios;

if (process.env.NODE_ENV !== 'production') {
   GestorAxios = axios.create({
      baseURL: 'http://localhost:3000'
   });
} else {
   GestorAxios = axios.create({
      baseURL: 'https://api.municipiosantarosa.gob.ar'
   });
}

/*
 
const GestorAxios = axios.create( { 
    baseURL : 'http://localhost:3000'
 });
*/

 // Setting para el server
/*
 const GestorAxios = axios.create( { 
    baseURL : 'https://api.municipiosantarosa.gob.ar'
 });
*/
 
 export default GestorAxios;

