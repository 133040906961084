
export  function isNullOrWhitespace( input ){
    if(input === null) return true;
    if(input === "") return true;

    try{
    if(input.length < 2) return true;
    }catch(e){}
    
    return false;
  }

  export const isNullOrWhitespace2 = ( input ) => {
    return !input || !input.trim();
  }


  export const convertirFecha = (fechaTurno) => {

    return (<>{convertirFechaToStr(fechaTurno)}</>);

  }

  export const convertirFechaToStr = (fechaTurno) => {

    const texto = fechaTurno.substr(8, 2) + "/" + fechaTurno.substr(5, 2) + "/" + fechaTurno.substr(0, 4);
    return texto;
  }
  
  export default isNullOrWhitespace;