import React, { useState, useEffect } from "react";
 
// components
import {CircularProgress} from '@material-ui/core';

import Profile from "../ciudadano/Profile";
import gestorAxios from '../../../config/axios';
import { useUserState } from "../../../context/UserContext";

export default function EditarPerfil(props) {

  var { token } = useUserState();

  const [faltanDatosPersonales, setFaltanDatosPersonales] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [datosUsuario, setDatosUsuario] = useState({
    email: "",
    nombre: "",
    apellido: "",
    telefono: "",
    domicilioLocal: "",
    barrioId: 0,
    domicilioResidencia: "",
    provinciaId: 0,
    id: 0
  });

  useEffect(() => {
    //console.log("EN USEEFFECT", datosUsuario, cargando);
    if (!cargando) {

      //console.log("ENTRA A CARGAR", token);
      setCargando(true);


      gestorAxios.post('/ciudadano/verifyToken', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {

        // //console.log("recibe datos", res.data.data);

        setDatosUsuario({
          email: res.data.data.email,
          nombre: res.data.data.nombre,
          apellido: res.data.data.apellido,
          telefono: res.data.data.telefono,
          domicilioLocal: res.data.data.domicilioLocal,
          BarrioId: res.data.data.BarrioId,
          domicilioResidencia: res.data.data.domicilioResidencia,
          provinciaId: res.data.data.provinciaId,
          fechaNac: res.data.data.fechaNac,
          id: res.data.data.id
        })

       // //console.log("********* VALIDA:", isNullOrWhitespace (res.data.data.barrioId ));
 
          setFaltanDatosPersonales(true);
        

      });


    }

  }, [datosUsuario, cargando, token]);

  const datosActualizados = () =>{
   props.history.push('/app/dashboard');
  }

  if (faltanDatosPersonales) {

    if (datosUsuario.email === null) {
      return (
        <div>
           <CircularProgress size={26} />
        </div>);
     
  } else {

    return (
      <>
         

        <Profile datos={datosUsuario} token={token} onUpdate={datosActualizados}></Profile>
      </>
    );
  }
  } else
  {
    return (
        <div>
          <CircularProgress size={26} />
        </div>);
  }


}
