import React, { useState } from "react";
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from '@material-ui/core/Button';

import { CircularProgress } from '@material-ui/core';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,

    KeyboardDatePicker,
} from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";

import gestorAxios from '../../../config/axios';

import useStyles from "../../../styles/globalStyleApp";
import Widget from "../../../components/Widget/Widget";

import { useForm } from 'react-hook-form';

import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { useUserState } from "../../../context/UserContext";

const PurpleSwitch = withStyles({
    switchBase: {
        paddingLeft: 0,
        color: blue[50],
        '&$checked': {
            color: blue[600],
        },
        '&$checked + $track': {
            backgroundColor: blue[600],
        },
    },
    checked: {},
    track: {},
})(Switch);



export default function TurnoForm(props) {

    var { token, userLogueadoId } = useUserState();

    const [confirmaDatos, setConfirmaDatos] = useState(false);

    const [buscandoOcupacion, setBuscandoOcupacion] = useState(false);
    const [datosOcupacion, setDatosOcupacion] = useState({});
    const [datosOcupacionCargados, setDatosOcupacionCargados] = useState(false);
    const [horaSeleccionada, setHoraSeleccionada] = useState('');

    const [dia_ConAtencionAlPublico, setDia_ConAtencionAlPublico] = useState(false);

    var classes = useStyles();
    const { handleSubmit } = useForm();

    const [areaSelectedId, setAreaSelectedId] = useState('');
    const [areaTurnoSelected, setAreaTurnoSelected] = useState({});
    const [opciondeAreaSelectedId, setOpciondeAreaSelectedId] = useState('');

    const [selectedDate, setSelectedDate] = React.useState(new Date());

    const [fechaSeleccionada, setFechaSeleccionada] = useState(false);
    const [fechaSeleccionadaNoValida, setFechaSeleccionadaNoValida] = useState(false);

    const [obs, setObs] = useState('');

    const [subAreaSelectedId, setSubAreaSelectedId] = useState('');

    const actualizarDatos = () => {

        if (areaTurnoSelected.calculoHoraTurno && horaSeleccionada === '') {
            alert("Seleccione un horario para poder confimar su turno");
        } else {


            const data = {
                opciondeAreaId: opciondeAreaSelectedId,

                fecha_YYYY: selectedDate.getFullYear(),
                fecha_MM: selectedDate.getMonth(),
                fecha_DD: selectedDate.getDate(),
                observacion: obs,
                SubAreaId: subAreaSelectedId,
                CiudadanoId: userLogueadoId
            }

            if (horaSeleccionada !== '') {
                data.horaTurno = horaSeleccionada;
            }

            //console.log("A GUARDAR ANTES: ", data);

            gestorAxios.post('/turno/turno/nuevo/', data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {

                props.onUpdate();
                //console.log("ACTUALIZADO: ", res);

            });
        }

    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setFechaSeleccionada(true);
        setBuscandoOcupacion(false);
        setHoraSeleccionada('');

    };



    const obtenerRangosTurnos = (horaDeIncioTurno, horaDeFinTurno, cantidadTurnos_Pordia, cantidadMinutosXTurno) => {

        const horaInicio = moment('01/01/2021 ' + horaDeIncioTurno);
        const horaFin = moment('01/01/2021 ' + horaDeFinTurno);
        const cantidad = cantidadTurnos_Pordia;
        const minutosXturno = cantidadMinutosXTurno;

        // buscar disponibilidad
        // ******************************
        if (!buscandoOcupacion) {
            setBuscandoOcupacion(true);
            setDatosOcupacion({});
            setDatosOcupacionCargados(false);

            const dataConsulta = {
                subareaId: areaTurnoSelected.id,
                fecha: selectedDate
            }
            console.log("CONSULTA OCU: ", dataConsulta);

            gestorAxios.post('/turno/turno/ocupacion/subarea', dataConsulta, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {

                console.log("recibe datos de OCU ***:, ", res.data);
                setDatosOcupacionCargados(true);
                setDatosOcupacion(res.data);

               

            });

        }



        if (datosOcupacionCargados) {

           

            const items = [];
            let hor = 0;
            let min = 0;

            for (let i = 0; i < cantidad; i++) {

                if (i > 0)
                    horaInicio.add(minutosXturno, 'm');
                //console.log("INICIO: ", i, horaInicio);
                hor = horaInicio._d.getHours();
                min = horaInicio._d.getMinutes();

                if (hor < 10)
                    hor = '0' + hor;
                if (min === 0) {
                    min = '00';
                } else {
                    if (min < 10)
                        min = '0' + min;
                }

                if (horaInicio >= horaFin) {
                    items.push(<Button
                        text={hor + ':' + min}
                        color="secondary"
                    />);
                } else {
                    if (horaSeleccionada === String(hor + ':' + min)) {

                        items.push(<><Button

                            style={{ backgroundColor: 'blue', color: 'white', margin: 10 }}

                        >
                            {hor + ':' + min}
                        </Button></>
                        );

                    } else {

                        if (!horarioOcupado(hor + ':' + min)) {
                            items.push(<><Button

                                style={{ backgroundColor: 'green', color: 'white', margin: 10 }}
                                onClick={(e) => {
                                    console.log("SELECCIONADO : ", e.target.innerHTML);
                                    setHoraSeleccionada(e.target.innerHTML);
                                }
                                }
                            >
                                {hor + ':' + min}
                            </Button></>
                            );
                        } else {
                            items.push(<><Button

                                style={{ backgroundColor: 'red', color: 'white', margin: 10 }}


                            >
                                {hor + ':' + min}
                            </Button></>
                            );
                        }
                    }
                }
            }

            return (
                <>
                    { items}
                </>
            )

        } else {
            return (
                <>
                   <CircularProgress size={26} />
                </>
            )
        }
    }

    const horarioOcupado = (hora) => {

        hora = hora + ':00';
      //  console.log("********* LLEGA OCUPACION22:", (datosOcupacion), hora);

            
            if (datosOcupacion.length) {
                const existe = datosOcupacion.filter(item => String(item.horaTurno) === String(hora));
               // console.log("ESTA", existe);
                if (existe.length > 0) {
                    return true;
                }

                return false;
            }
        
        return false;
    }


    // la primer fecha se incrementa porque en la base de datos se guarda en formato EN y resta un dia.
    const comparaFechas = (fecha1, fecha2) => {
        const dia = new Date(fecha1);
        dia.setDate(dia.getDate() + 1);


        return dia.toLocaleDateString('es-ES') === fecha2.toLocaleDateString('es-ES');

    }

    const verificaSiAtiendeEnDiaSeleccionado = (fecha, oaSeleccionada) => {

        let retorna = true;

        if (fecha) {

            if (fecha.getDay() === 1 && !oaSeleccionada.lunes)
                retorna = false;

            if (fecha.getDay() === 2 && !oaSeleccionada.martes)
                retorna = false;

            if (fecha.getDay() === 3 && !oaSeleccionada.miercoles)
                retorna = false;

            if (fecha.getDay() === 4 && !oaSeleccionada.jueves)
                retorna = false;

            if (fecha.getDay() === 5 && !oaSeleccionada.viernes)
                retorna = false;


            setFechaSeleccionadaNoValida(!retorna);
            return retorna;
        } else {

            setFechaSeleccionadaNoValida(false);
            return true;
        }
    }


    const InfoTurno = (propsComponent) => {


        const areaSeleccionada = props.datos.subAreas.filter(item => item.id === subAreaSelectedId)[0];
        let opciondeAreaSeccionada = null;

        if (opciondeAreaSelectedId !== "") {
            opciondeAreaSeccionada = props.datos.opciondeArea.filter(item => item.id === opciondeAreaSelectedId)[0];
        }

        let esFinDeSemana = false;
        let diaConAtencionAlPublico = true;
        let fechaEnCalendarioNoLaboral = false;
        let enCalendario = {};
        let cantidadTurnos = {};
        let cantidadReservados = 0;

        if (fechaSeleccionada) {
            const fecha = selectedDate;
            if (fecha.getDay() === 6 || fecha.getDay() === 0) {
                esFinDeSemana = true;
            }

            enCalendario = props.datos.calendario.filter(item => comparaFechas(item.fechaNoLaborable, fecha));

            fechaEnCalendarioNoLaboral = enCalendario.length > 0;

            // //console.log("esta en CAL: ", enCalendario.length);
            if (!fechaEnCalendarioNoLaboral) {
                enCalendario = props.datos.calendarioArea.filter(
                    item => comparaFechas(item.fechaNoLaborable, fecha) && item.AreaId === areaSelectedId);

                fechaEnCalendarioNoLaboral = enCalendario.length > 0;
            }



            // Verifica cantidad de turnos en ese día

            const turnosReservados = props.datos.turnos.filter(item => comparaFechas(item.fechaTurno, fecha) && item.SubAreaId === subAreaSelectedId);



            cantidadTurnos = areaSeleccionada.cantidadTurnos_Pordia;
            cantidadReservados = 0;
            try {
                if (turnosReservados.length > 0) {
                    cantidadReservados = turnosReservados[0].count;
                }
            } catch (err) {

            }

            //console.log("ENCONTRADOS: ", turnosReservados, turnosReservados.length);


            if (fechaEnCalendarioNoLaboral || esFinDeSemana) {
                setFechaSeleccionadaNoValida(true);
            } else {

                if ((cantidadTurnos - cantidadReservados) > 0) {
                    //setFechaSeleccionadaNoValida(false);
                    diaConAtencionAlPublico = verificaSiAtiendeEnDiaSeleccionado(selectedDate, opciondeAreaSeccionada);

                    setDia_ConAtencionAlPublico(diaConAtencionAlPublico);

                } else {
                    setFechaSeleccionadaNoValida(true);
                }
            }





        }

        setAreaTurnoSelected(areaSeleccionada);

        console.log("areaSeleccionada", areaSeleccionada, opciondeAreaSeccionada);
        //observacionParaTurnos
        return (
            <>

                { ((fechaSeleccionada) && (esFinDeSemana)) ?

                    <div style={{ color: 'red' }}> Es fin de semana </div>

                    :
                    <>
                        { (fechaEnCalendarioNoLaboral) ?

                            <div style={{ color: 'red' }}> Es feriado laboral <br />

                                <b>Nota:</b> {enCalendario[0].nota}
                            </div>
                            :
                            <div>

                                {diaConAtencionAlPublico ?

                                    <div>

                                        {areaSeleccionada.horaDeIncioTurno ?
                                            <>
                                                <b>Horario de atención: </b> {areaSeleccionada.horaDeIncioTurno.substr(0, 5)} a  {areaSeleccionada.horaDeFinTurno.substr(0, 5)} <br />
                                            </>
                                            :
                                            <>Horario de inicio de atencíon no definido. Intente contactar antes de presentarse, para evitar contratiempos</>
                                        }

                                        {fechaSeleccionada ?
                                            <div>

                                                {(cantidadTurnos - cantidadReservados) > 0 ?
                                                    <div>
                                                        <b>Turnos disponibles:</b> {cantidadTurnos - cantidadReservados} <br />
                                                    </div>
                                                    :
                                                    <div style={{ color: 'red' }}> No hay turnos disponibles en esta fecha
                                            </div>
                                                }
                                            </div>
                                            :
                                            <div></div>}

                                        {opciondeAreaSeccionada && opciondeAreaSeccionada.observacionParaTurnos && (cantidadTurnos - cantidadReservados) > 0 ?
                                            <div style={{ color: "black", backgroundColor: "yellow", padding: 10, borderRadius: 7, paddingBottom: 10 }}><b>Importante:</b> {opciondeAreaSeccionada.observacionParaTurnos}
                                                {opciondeAreaSeccionada.link &&
                                                    <>
                                                        <br /><br />
                                                        <b>ENLACE ÚTIL:<br />{opciondeAreaSeccionada.textoLink}</b>
                                                        <a style={{ color: "white", backgroundColor: "black", padding: 5, borderRadius: 4, margin: 10, textDecoration: 'none' }} href={opciondeAreaSeccionada.link} target="_blank"> IR AL LINK </a>
                                                    </>
                                                }

                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>

                                    :
                                    <div >  {(opciondeAreaSeccionada && fechaSeleccionada) &&


                                        <div style={{ backgroundColor: 'red', color: 'white', padding: 10, borderRadius: 10 }}>
                                            Este tipo de turno no esta disponible en el dia de la semana seleccionado <br />
                                            <div style={{ color: "black", backgroundColor: "yellow" }}><b>Importante:</b> {opciondeAreaSeccionada.observacionParaTurnos}</div>
                                        </div>

                                    }
                                    </div>
                                }
                            </div>
                        }
                    </>
                }
            </>

        );

    }

    if (!props.datos.areas) {
        return (
            <div> <CircularProgress size={26} /></div>
        );
    } else {


        //  console.log("*** selectedDate: ", selectedDate);

        return (
            <React.Fragment>
                <Grid container item xs={12} md={12} lg={12}>
                    <Widget disableWidgetMenu>

                        <Typography variant="h6" gutterBottom>
                            Nuevo turno
      </Typography>

                        <form className={classes.form} noValidate onSubmit={handleSubmit((data) => {
                            actualizarDatos(data);
                        })}>


                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl style={{ width: '100%' }} >
                                        <InputLabel style={{ paddingLeft: 10 }} id="demo-simple-select-label">Seleccione un área</InputLabel>
                                        <Select
                                            id="areaId"
                                            name="areaId"
                                            fullWidth
                                            label="Seleccione un área"
                                            variant="filled"
                                            value={areaSelectedId}
                                            onChange={(event) => {
                                                setHoraSeleccionada('');
                                                setAreaSelectedId(event.target.value);
                                                // seleccionaSecretaria(event);
                                            }}
                                        >
                                            {props.datos.areas ? props.datos.areas.map(item =>

                                                <MenuItem key={item.id} value={item.id}>{item.nombreArea}</MenuItem>

                                            ) : <div></div>}
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    {areaSelectedId !== "" ?

                                        <FormControl style={{ width: '100%' }} >
                                            <InputLabel style={{ paddingLeft: 10 }} id="demo-simple-select-label">Seleccione subárea</InputLabel>
                                            <Select
                                                id="SubAreaId"
                                                name="SubAreaId"
                                                fullWidth
                                                label="Seleccione subárea"
                                                variant="filled"
                                                value={subAreaSelectedId}
                                                onChange={(event) => {
                                                    setHoraSeleccionada('');
                                                    setSubAreaSelectedId(event.target.value);
                                                }}
                                            >
                                                {props.datos.subAreas ? props.datos.subAreas.filter(item => item.AreaId === areaSelectedId).map(item =>

                                                    <MenuItem key={item.id} value={item.id}>{item.nombreSubArea}</MenuItem>

                                                ) : <div></div>}
                                            </Select>
                                        </FormControl>
                                        :
                                        <></>

                                    }

                                </Grid>


                                <Grid item xs={12} sm={6}>

                                    {subAreaSelectedId !== "" ?

                                        <FormControl style={{ width: '100%' }} >
                                            <InputLabel style={{ paddingLeft: 10 }} id="demo-simple-select-label">Seleccione tipo de turno</InputLabel>
                                            <Select
                                                id="opciondeAreaId"
                                                name="opciondeAreaId"
                                                fullWidth
                                                label="Seleccione tipo de turno"
                                                variant="filled"
                                                value={opciondeAreaSelectedId}
                                                onChange={(event) => {
                                                    setOpciondeAreaSelectedId(event.target.value);
                                                }}
                                            >
                                                {props.datos.opciondeArea ? props.datos.opciondeArea.filter(item => item.SubAreaId === subAreaSelectedId).map(item =>

                                                    <MenuItem key={item.id} value={item.id}>{item.nombreOpcionArea}</MenuItem>

                                                ) : <div></div>}
                                            </Select>
                                        </FormControl>
                                        :
                                        <></>

                                    }


                                    {opciondeAreaSelectedId !== "" ?


                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>

                                            <Grid style={{ marginTop: 10 }} container justify="space-around">

                                                <KeyboardDatePicker
                                                    fullWidth

                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    disablePast
                                                    format="dd/MM/yyyy"
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    helperText="Selecciona la fecha del turno"

                                                />

                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                        :
                                        <></>

                                    }
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {subAreaSelectedId !== "" ?
                                        <>
                                            <div>  INFO DEL TURNO</div>
                                            <hr />
                                            <InfoTurno />
                                        </>
                                        :
                                        <></>
                                    }
                                </Grid>




                                <Grid item xs={12}>

                                    {fechaSeleccionada ?
                                        <>

                                            {areaTurnoSelected &&
                                                <>
                                                    {areaTurnoSelected.calculoHoraTurno && dia_ConAtencionAlPublico && !fechaSeleccionadaNoValida ?
                                                        <>
                                                            <h3>SELECCIONE EL HORARIO DE SU TURNO</h3>
                                                            {obtenerRangosTurnos(areaTurnoSelected.horaDeIncioTurno, areaTurnoSelected.horaDeFinTurno, areaTurnoSelected.cantidadTurnos_Pordia, areaTurnoSelected.cantidadMinutosXTurno)}
                                                        </>
                                                        :
                                                        <> </>
                                                    }
                                                </>
                                            }

                                            <br />
                                            <h3>INDIQUE SI ES NECESARIO UNA NOTA PARA LA PERSONA QUE LO ATENDERÁ EN SU TURNO</h3>
                                            <TextField
                                                defaultValue={obs}
                                                variant="filled"
                                                multiline
                                                rows={4}
                                                onChange={(e) => setObs(e.target.value)}
                                                id="obs"
                                                name="obs"
                                                label="Nota"
                                                fullWidth
                                                helperText="Completa solamente si necesitas enviar un mensaje al responsable de atención al público"
                                                inputProps={{ maxLength: 254 }}

                                            />
                                        </>
                                        :
                                        <></>
                                    }
                                </Grid>



                                <Grid item xs={12} style={{ margin: 20 }}>


                                </Grid>






                                <Grid item sm={6} xs={12}>

                                    {fechaSeleccionada ?
                                        <div>

                                            {fechaSeleccionadaNoValida ?
                                                <div style={{ color: 'red' }}>
                                                    Seleccione una fecha válida

                                            </div>

                                                :

                                                <FormControlLabel

                                                    control={<PurpleSwitch
                                                        checked={confirmaDatos}
                                                        onChange={() => { setConfirmaDatos(!confirmaDatos) }}
                                                        name="confirma"
                                                    />}
                                                    labelPlacement="end"
                                                    label="Confirmo que asistiré al turno indicado y en caso de algún inconveniente me comprometo a cancelarlo antes de la fecha."
                                                />

                                            }



                                        </div>
                                        :
                                        <> Complete la información del turno para poder confirmarlo.<br />
                                            <b>Paso 1:</b> Seleccione el área<br />
                                            <b>Paso 2:</b> Seleccione subárea<br />
                                            <b>Paso 3:</b> Seleccione tipo de turno<br />
                                            <b>Paso 4:</b> Indique la fecha del turno.</>
                                    }

                                </Grid>
                                <Grid item sm={6} xs={12}
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"

                                >
                                    <Button
                                        disabled={!confirmaDatos}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        CONFIRMAR
                                </Button>
                                </Grid>



                            </Grid>
                        </form>
                    </Widget>

                </Grid>
            </React.Fragment>
        );
    }
}