import React from 'react';
import ReactDOM from 'react-dom';

import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";

import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
  
import App from './AppRoutes';
 
import Themes from "./themes";


ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
    <ThemeProvider theme={Themes.default}>
        <CssBaseline /> 

          <App />  
          
      </ThemeProvider>
    </UserProvider>
  </LayoutProvider>,
  document.getElementById("root"),
);

 
