import React, { useState, useEffect } from "react";
import {
  Grid,
  Button
} from "@material-ui/core";
 
import {CircularProgress} from '@material-ui/core';
// styles
import useStyles from "../../../styles/dashboard/styles";

// components
import Widget from "../../../components/Widget/Widget";
import PageTitle from "../../../components/PageTitle/PageTitle";
 
import Divider from '@material-ui/core/Divider';
import Table from "./components/TablePuntosInicioReclamo/Table";
import Notification from "../../../components/Notification/Notification";

import isNullOrWhitespace from "../../../utiles/validaciones";

import Profile from "./Profile";

import gestorAxios from '../../../config/axios';

import { useUserState } from "../../../context/UserContext";

export default function NuevoReclamo(props) {
  var classes = useStyles();

  var { token, userLogueadoId } = useUserState();

  const [faltanDatosPersonales, setFaltanDatosPersonales] = useState(false);
  const [cargando, setCargando] = useState(false);
 
  const [puntosInicioReclamos, setPuntosInicioReclamos] = useState({});
  const [datosUsuario, setDatosUsuario] = useState({
    email: "",
    nombre: "",
    apellido: "",
    telefono: "",
    domicilioLocal: "",
    barrioId: 0,
    domicilioResidencia: "",
    provinciaId: 0,
    id: 0
  });

  useEffect(() => {
    //console.log("EN USEEFFECT", datosUsuario, cargando);
    if (!cargando) {

      //console.log("ENTRA A CARGAR", token, userLogueadoId);
      setCargando(true);

   

      gestorAxios.get(`/reclamo/puntosinicio/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {

        //console.log("recibe datos", res.data);
        setPuntosInicioReclamos(res.data);
      });



      gestorAxios.post('/ciudadano/verifyToken', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {

        //console.log("recibe datos", res);



        setDatosUsuario({
          email: res.data.data.email,
          nombre: res.data.data.nombre,
          apellido: res.data.data.apellido,
          telefono: res.data.data.telefono,
          domicilioLocal: res.data.data.domicilioLocal,
          BarrioId: res.data.data.BarrioId,
          domicilioResidencia: res.data.data.domicilioResidencia,
          provinciaId: res.data.data.provinciaId,
          id: res.data.data.id
        })

        // //console.log("********* VALIDA:", isNullOrWhitespace (res.data.data.barrioId ));

        if (isNullOrWhitespace(res.data.data.barrioId) || isNullOrWhitespace(res.data.data.nombre) || isNullOrWhitespace(res.data.data.apellido) ||
          isNullOrWhitespace(res.data.data.domicilioLocal) || isNullOrWhitespace(res.data.data.telefono)) {
          //console.log("detecta que faltan datos");
          setFaltanDatosPersonales(true);
        }

      });


    }

  });

  const contactar = () => {
    window.location.assign('https://www.municipiosantarosa.gob.ar/informacion-util/');
  }

  const onCancelTurno = () => {

    setCargando(false);

  }

  const datosActualizados = () => {
    setFaltanDatosPersonales(false);
  }

  if (faltanDatosPersonales) {

    if (datosUsuario.email === null) {
      return (
        <div>
          <CircularProgress size={26} />
        </div>);

    } else {

      return (
        <>
          <Grid item xs={12} md={12} lg={12}>
            <Widget title="IMPORTANTE" disableWidgetMenu>
              <Notification
                className={classes.notificationItem}
                shadowless
                type="defence"
                message="Completa tus datos personales antes de comenzar a utiliar la APP"
                variant="contained"
                color="secondary"
              />

            </Widget>
          </Grid>

          <Divider className={classes.separador} variant="middle" />

          <Profile datos={datosUsuario} token={token} onUpdate={datosActualizados}></Profile>
        </>
      );
    }
  } else {

    return (
      <>
        <PageTitle title="RECLAMOS" button={
          <Button
          style={{ marginLeft: 20, marginTop: -10 }}
            variant="contained"
            size="medium"
            color="secondary"
            onClick={contactar}
          >
            INFO ÚTIL / CONTACTO
      </Button>} />
        <Grid container spacing={4}>
        




          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Widget
              disableWidgetMenu={true}
              title="¿CÓMO INICIAR UN RECLAMO?"
              upperTitle
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <h4>Los nuevos reclamos debes ser iniciados personalmente en cualquiera de los puntos de atención al público que abajo se indican. Nuestro sistema automaticamente lo derivará a la dependencia encargada de resolverlo y Usted podrá hacer su seguimiento a través de ésta App.</h4>
            
            </Widget>
          </Grid>


          <Grid item xs={12}>

            <Widget
              disableWidgetMenu={true}
              title="PUNTOS DE INICIO"
              upperTitle
              noBodyPadding
              bodyClass={classes.tableWidget}

            >

              <div style={{ marginTop: 15 }}>
                <Table onCancelTurno={onCancelTurno} data={puntosInicioReclamos} />
              </div>
            </Widget>
          </Grid>
        </Grid>
      </>
    );

  }



}
