import React from "react";

import gestorAxios from '../config/axios';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true, token: localStorage.getItem("token"), 
      userLogueadoId: action.userLogueadoId, 
      email: action.email,     
      dni: action.dni,     
      domicilioLocal: action.domicilioLocal,
      nombre: action.nombre,
      apellido: action.apellido,
      telefono: action.telefono,    
      bloqueado: action.bloqueado,
      BarrioId: action.BarrioId,
      fechaNac: action.fechaNac, 
      datosCargadosOK: action.datosCargadosOK     
      };
   
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false,
        token: undefined,
        domicilioLocal: undefined,
        nombre: undefined,
        apellido: undefined,
        telefono: undefined,
        userLogueadoId: 0,
        email: undefined,
        dni: undefined,
        bloqueado: undefined,
        BarrioId: undefined,
        fechaNac: undefined, 
        datosCargadosOK: false };
    case "LOGIN_IN_PROCESS":
      return { ...state, isAdmin: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const controlaDatos = (data) => {

  let ret = false;
  console.log("-----")
  console.log("-----")
  console.log("-----", data)
  if(data.BarrioId && data.nombre && data.apellido && data.telefono && data.fechaNac
    && data.domicilioLocal)
  {
    ret = true;
  }

  return ret;

}


function UserProvider({ children }) {


  const token = localStorage.getItem("token");

  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: false,
    token: token,
    domicilioLocal: undefined,
    nombre: undefined,
    apellido: undefined,
    telefono: undefined,
    userLogueadoId: 0,
    email: undefined,
    dni: undefined,
    bloqueado: undefined,
    BarrioId: undefined,
    fechaNac: undefined, 
    datosCargadosOK: false
  });
  
  console.log("isAuthenticated: ", state.isAuthenticated);
  console.log("TOKENN: ", token);
  
  const loginInProcess = localStorage.getItem("EnRefresh");
  console.log("loginInProcess: ", loginInProcess);

  if (state.token && state.dni === undefined && !loginInProcess ) {

    dispatch({ type: "LOGIN_IN_PROCESS" });

    try{

    localStorage.setItem("EnRefresh",true);
    
    gestorAxios.post('/ciudadano/verifyToken/', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {
      //console.log("TIPO RES: ", res);
      console.log("revisar TOKEN: ", res.data.data);
      localStorage.removeItem("EnRefresh");
      
      if (res.status === 200) {
        
        // si es correcto, se eliminan datos de un posible bloqueo
        localStorage.removeItem("bloqueado");
        localStorage.removeItem("obsBloqueo");
        
        const datosCargadosOK = controlaDatos(res.data.data);
        console.log("*******************");
        console.log("*******************");
        console.log("*******************");
        console.log("******: ", res.data.data, datosCargadosOK);
      
          dispatch({ type: "LOGIN_SUCCESS", 
          userLogueadoId: res.data.data.id, 
          email: res.data.data.email, 
          dni: res.data.data.dni,                                            
          domicilioLocal: res.data.data.domicilioLocal,
          nombre: res.data.data.nombre,
          apellido: res.data.data.apellido,
          telefono: res.data.data.telefono,    
          bloqueado: res.data.data.bloqueado,
          BarrioId: res.data.data.BarrioId,
          fechaNac: res.data.data.fechaNac, 
          datosCargadosOK: datosCargadosOK   

        });
       
      } else {

        localStorage.removeItem("token");

        if (res.status === 201) {
          localStorage.setItem('bloqueado', true);
          //console.log('EN CONTEXT: ', res.data.data)
          if (res.data.data) {
            localStorage.setItem('obsBloqueo', res.data.data.obsBloqueo);
          }
          else {
            localStorage.setItem('obsBloqueo', 'Su acceso no esta disponible, por favor consulte en mesa de entrada');           
          }
          dispatch({ type: "SIGN_OUT_SUCCESS" });
           
        }
        else {
          
          dispatch({ type: "SIGN_OUT_SUCCESS" });
        }
      }

    });
  }catch(e)
  {
    localStorage.removeItem("EnRefresh");
    localStorage.removeItem("token");
     dispatch({ type: "SIGN_OUT_SUCCESS" });
  }

  }




  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}


// ###########################################################

function registerUser(dispatch, dni, email, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  // viene de la API

  if (!!dni && !!password && !!email) {

    const nuevoRegistro = {
      email: email,
      dni: dni,
      password: password
    }

    //console.log('nuevo registro: ', nuevoRegistro);

    gestorAxios.post('/ciudadano/nuevo/', nuevoRegistro).then(
      res => {

        //console.log("creado nuevo usuario: ", res, res.data);

        loginUser(
          dispatch,
          dni,
          password,
          history,
          setIsLoading,
          setError,
        );


      }).catch(err => {

        setError(true)
        setIsLoading(false)
        //console.log("Error creando nuevo usuario: ", err);
      });


  }
  else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function loginUser(dispatch, loginData, password, history, setIsLoading, setError, isLoginAdmin) {
  setError(false);
  setIsLoading(true);

  // viene de la API
  //console.log("entra en login");

  let infoLogin = {};
  let urlLogin = '';

 /* if (isLoginAdmin) {
    urlLogin = '/usuario/login/';
    infoLogin = {
      email: loginData,
      password: password
    }
  } else {*/
    urlLogin = '/ciudadano/login/';
    infoLogin = {
      dni: loginData,
      password: password
    }
  //}
  //console.log("Envia obj login: ", infoLogin);

  if (!!loginData && !!password) {

    gestorAxios.post(urlLogin, infoLogin).then(
      res => {

        //console.log("login correcto: ", res, res.data);

        const { token } = res.data.data;
        localStorage.removeItem("token");
        localStorage.setItem('token', token);
        setError(null)
        setIsLoading(false)

        /*  if (isAdmin) {
            dispatch({ type: 'LOGIN_ADMIN_SUCCESS' })
            history.push('/admin/dashboard')
          } else {*/
      /*  if (isLoginAdmin) {
          dispatch({
            type: 'LOGIN_ADMIN_SUCCESS', userLogueadoId: res.data.data.id,
            isSuperAdmin: res.data.data.isSuperAdmin,
            adminAreaId: res.data.data.adminAreaId,
            adminSubAreaId: res.data.data.adminSubAreaId,
            email: res.data.data.email, dni: res.data.data.dni
          })
          history.push('/admin/dashboard')
        }
        else {*/

          const datosCargadosOK = controlaDatos(res.data.data);
        console.log("*******************");
        console.log("*******************");
        console.log("*******************");
        console.log("******: ", res.data.data, datosCargadosOK);


          dispatch({ type: 'LOGIN_SUCCESS',
          userLogueadoId: res.data.data.id, 
          email: res.data.data.email, 
          dni: res.data.data.dni,                                            
          domicilioLocal: res.data.data.domicilioLocal,
          nombre: res.data.data.nombre,
          apellido: res.data.data.apellido,
          telefono: res.data.data.telefono,    
          bloqueado: res.data.data.bloqueado,
          BarrioId: res.data.data.BarrioId,
          fechaNac: res.data.data.fechaNac, 
          datosCargadosOK: datosCargadosOK   
          })
          history.push('/app/dashboard')
        //}




      }).catch(err => {

        setError(true)
        setIsLoading(false)
        //console.log("Error en login usuario: ", err);
      });



  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}


// ###########################################################

function verifyToken(dispatch, history, verificaEnCadaAcceso, loginInProcess) {

  loginInProcess = localStorage.getItem("EnRefresh");

  if (loginInProcess) {
    console.log("YA EN PROCESO DE VERIFYTOKEN");
  } else {

    const token = localStorage.getItem("token");
    localStorage.setItem('EnRefresh', true);
    console.log("VERIFICANDO TOKEN ******", token, loginInProcess);

    dispatch({ type: "LOGIN_IN_PROCESS" });



    gestorAxios.post('/ciudadano/verifyToken/', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {

      if (res.status === 200) {

        localStorage.removeItem("bloqueado");
        localStorage.removeItem("obsBloqueo");
        localStorage.removeItem("EnRefresh");


        console.log("********  *** * * * ***  VERIFY TOKER CORRECTO", res.data.data.token);
        localStorage.setItem('token', res.data.data.token);

        const datosCargadosOK = controlaDatos(res.data.data);
      
          dispatch({ type: "LOGIN_SUCCESS", 
          userLogueadoId: res.data.data.id, 
          email: res.data.data.email, 
          dni: res.data.data.dni,                                            
          domicilioLocal: res.data.data.domicilioLocal,
          nombre: res.data.data.nombre,
          apellido: res.data.data.apellido,
          telefono: res.data.data.telefono,    
          bloqueado: res.data.data.bloqueado,
          BarrioId: res.data.data.BarrioId,
          fechaNac: res.data.data.fechaNac, 
          datosCargadosOK: datosCargadosOK   

        });
        /* } else {
           dispatch({ type: "LOGIN_SUCCESS", userLogueadoId: res.data.data.id, email: res.data.data.email, dni: res.data.data.dni });
         }*/

        history.push('/admin/dashboard');


      } else {
        console.log("NOOOOO   ********  *** * * * ***  VERIFY TOKER CORRECTO", res.data);
        if (res.status === 201) {
          localStorage.setItem('bloqueado', true);
          //console.log('EN CONTEXT: ', res.data.data)
          if (res.data.data) {
            localStorage.setItem('obsBloqueo', res.data.data.obsBloqueo);
          }
          else {
            localStorage.setItem('obsBloqueo', 'Su acceso no esta disponible, por favor consulte en mesa de entrada');
          }
          dispatch({ type: "SIGN_OUT_SUCCESS" });

        }
        else {
          // localStorage.removeItem("token");
          dispatch({ type: "SIGN_OUT_SUCCESS" });
        }
      }

    }).catch(error => {
      //console.log("ERROR Y CATCH");
      //localStorage.removeItem("token");
      dispatch({ type: "SIGN_OUT_SUCCESS" });
    })

  }


}


export { UserProvider, useUserState, useUserDispatch, loginUser, registerUser, signOut, verifyToken };
