import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  LibraryBooks as LibraryIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import AddAlertIcon from '@material-ui/icons/AddAlert';
import RoomIcon from '@material-ui/icons/Room';
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "../../../styles/components/Sidebar/styles";

import { useUserState  } from "../../../context/UserContext";

// components
import SidebarLink from "../../../components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../../context/LayoutContext";



const structure = [
  { id: 0, label: "Mesa de entrada", link: "/app/dashboard", icon: <HomeIcon /> },

  { id: 1, type: "title", label: "ACCESOS" },
  { id: 2, label: "Datos personales", link: "/app/perfil", icon: <LibraryIcon /> },


];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var { datosCargadosOK } = useUserState();

  console.log('++++', datosCargadosOK)
  const itemsYacargados = structure.find(item => item.id === 3);

if(datosCargadosOK && !itemsYacargados)
{ 

  structure.push(  
    { id: 3, label: "Nuevo turno", link: "/app/nuevoturno", icon: <AddAlertIcon /> },
    { id: 4, label: "Nuevo reclamo", link: "/app/nuevoreclamo", icon: <RoomIcon /> }
);


}

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            onClick={() => {
              if (!isPermanent) {
                toggleSidebar(layoutDispatch);
              }
            }}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
