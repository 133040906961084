import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    maxWidth: "98vw",
     
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "98vw",
    minHeight: "98vh",
  },
  contentShift: {
    width: "98vw",
    
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    '&:not(:first-child)': {
      paddingLeft: 15
    }
  }
}), {index: 7});
