import React from "react";
import Button from '@material-ui/core/Button';
 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
 
//import CloseIcon from '@material-ui/icons/Close';
import RoomIcon from '@material-ui/icons/Room';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import './styles.css';

 
import useStyles from "../../../../../styles/dashboard/styles";

import gestorAxios from '../../../../../config/axios';

import { useUserState } from "../../../../../context/UserContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TableComponent({ data, onCancelTurno }) {

  const classes = useStyles();
 // console.log("DATAAAA: ", data);

  var { token, userLogueadoId } = useUserState();

  const keys = ['Lugar', 'Información', '',];

  const [open, setOpen] = React.useState(false);
  const [datosCancelaTurno, setDatosCancelaTurno] = React.useState({});
  const [textoCancelaTurno, setTextoCancelaTurno] = React.useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseElimina = () => {

    // Cancelacion del turno
   // console.log("CANCELA CON: ", userLogueadoId, datosCancelaTurno.id, token);




    gestorAxios.put(`/turno/turno/cancelar/${userLogueadoId}/${datosCancelaTurno.id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {

      setDatosCancelaTurno({});
      setTextoCancelaTurno("");

      onCancelTurno();

    });

    setOpen(false);
  }
  
 

   
 

  if (data.length > 0) {

   console.log("puntos INICIO: ", data)

    return (
      <>
      

      <Table >
      <Thead   >
        <Tr   >
        {keys.map(item =>  <Th key={item}> {item}</Th>)}                        
        </Tr>
      </Thead>
      <Tbody>

      {data.map(({ id, nombre, Area, direccion, horaInicio, horaFin, telefono, observacion, urlMapa }) => (
        
            <Tr  key={id}>
              <Td style={{ color: "black", backgroundColor: "lightblue" , textAlign: "center", padding: 10 }} className="pl-3 fw-normal"><b>{nombre}</b> <br /> 
              {direccion}
              
               </Td>
              <Td style={{ color: "black", backgroundColor: "#e3f2fd", padding: 10 }}>   

               
              <b>Tel: {telefono} </b> <br />

                <b>Horario de atención: </b>

                {horaInicio.substr(0, 5)} a {horaFin.substr(0, 5)} <br />
                <br />
                <b>{observacion} </b>  

              </Td>

           
              <Td style={{ color: "black", backgroundColor: "#e3f2fd", textAlign: "center", padding: 10 }}>

              { urlMapa &&

<div style={{ height: 40}}>
                <a style={{ backgroundColor: "white", borderRadius: 20, padding: 15, textDecoration: "none"  }}  href={urlMapa} target="_blank">
                <RoomIcon style={{ marginBottom: -7}} /> VER EN MAPA</a>

</div>
              
              }
              </Td>


            </Tr>
           
          
          ))}


       
      </Tbody>
    </Table>

 

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Desea cancelar su turno?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              DATOS DEL TURNO:<br />
              {textoCancelaTurno}

            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: 20 }}>
            <Button onClick={handleClose} color="primary">
              No cancelar
          </Button>
            <Button variant="contained" onClick={handleCloseElimina} color="secondary">
              Si quiero cancelar mi turno
          </Button>
          </DialogActions>
        </Dialog>

      </>
    )
  } else {
    return (
      <> <p style={{ margin: 20 }}> No hay turnos reservados</p></>
    )
  }
 
}