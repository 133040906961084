import React from "react";
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
//import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import './styles.css';


import useStyles from "../../../../../styles/dashboard/styles";

import gestorAxios from '../../../../../config/axios';

import { useUserState } from "../../../../../context/UserContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TableComponent({ data, onCancelTurno }) {

  const classes = useStyles();
  // console.log("DATAAAA: ", data);

  var { token, userLogueadoId } = useUserState();

  const keys = ['Fecha', 'Turno para', 'Acción',];

  const [open, setOpen] = React.useState(false);
  const [datosCancelaTurno, setDatosCancelaTurno] = React.useState({});
  const [textoCancelaTurno, setTextoCancelaTurno] = React.useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseElimina = () => {

    // Cancelacion del turno
    // console.log("CANCELA CON: ", userLogueadoId, datosCancelaTurno.id, token);




    gestorAxios.put(`/turno/turno/cancelar/${userLogueadoId}/${datosCancelaTurno.id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {

      setDatosCancelaTurno({});
      setTextoCancelaTurno("");

      onCancelTurno();

    });

    setOpen(false);
  }


  const convertirFecha = (fechaTurno) => {

    return (<div key={fechaTurno} >{convertirFechaToStr(fechaTurno)}</div>);

  }

  const convertirFechaToStr = (fechaTurno) => {

    const texto = fechaTurno.substr(8, 2) + "/" + fechaTurno.substr(5, 2) + "/" + fechaTurno.substr(0, 4);
    return texto;
  }






  if (data.length > 0) {

    console.log("datos Turnos: ", data)

    return (
      <>


        <Table >
          <Thead >
            <Tr  >
              {keys.map(item => <Th key={item}>{item}</Th>)}
            </Tr>
          </Thead>
          <Tbody>

            {data.map(({ id, fechaTurno, OpcionDeArea, SubArea, cancelado, motivo, horaTurno }) => (

              <Tr key={id}>
                <Td style={{ color: "black", backgroundColor: "lightblue", textAlign: "center", padding: 10 }} className="pl-3 fw-normal"> {convertirFecha(fechaTurno)}</Td>
                <Td style={{ color: "black", backgroundColor: "#e3f2fd", padding: 10 }}><b>{OpcionDeArea.nombreOpcionArea}</b> - {SubArea.nombreSubArea}  <br />

                  {OpcionDeArea.observacionParaTurnos ?
                    <>
                      <b>IMPORTANTE:</b> {OpcionDeArea.observacionParaTurnos}<br />
                    </>
                    :
                    <></>
                  }


                  

                  {horaTurno ?
                    <>
                      <div style={{ backgroundColor: 'yellow', paddingTop: 10, paddingBottom: 10, margin: 10, borderRadius: 10, textAlign: 'center' }}>
                      <b> HORA DE SU TURNO: </b>
                       <h1> {horaTurno.substr(0, 5)}</h1>
                       <b> Por favor sea puntual para poder mantener el orden de los turnos solicitados </b>
                      </div>
                    </>
                    :
                    <>
                    <div style={{ backgroundColor: 'lightblue', paddingTop: 10, paddingBottom: 10, margin: 10, borderRadius: 10, textAlign: 'center' }}> 
                    <b> Horario de atención: </b>
                    <h1>  {SubArea.horaDeIncioTurno.substr(0, 5)} a {SubArea.horaDeFinTurno.substr(0, 5)} </h1>
                    <b> POR ORDEN DE LLEGADA </b>
                    </div></>
                  }



                  <b>Mensaje enviado: </b> {motivo}

                </Td>


                <Td style={{ color: "black", backgroundColor: "#e3f2fd", textAlign: "center", padding: 10 }}>

                  {cancelado ?

                    <> <Chip style={{ backgroundColor: "#ffea00" }} icon={<ErrorOutlineIcon />} size="small" label="TURNO CANCELADO" /> </>
                    :

                    <Button color="secondary" style={{ backgroundColor: "white", borderRadius: 20 }}
                      className={classes.button}
                      startIcon={<DeleteIcon />} onClick={() => {


                        const texto = OpcionDeArea.nombreOpcionArea + " - " + SubArea.nombreSubArea + " - Fecha: " + convertirFechaToStr(fechaTurno);

                        setTextoCancelaTurno(texto);
                        setDatosCancelaTurno({
                          id,
                          fechaTurno,
                          OpcionDeArea,
                          SubArea
                        })
                        handleClickOpen();

                      }}>
            CANCELAR
               </Button>
                  }

                </Td>


              </Tr>


            ))}



          </Tbody>
        </Table>



        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Desea cancelar su turno?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
            DATOS DEL TURNO:<br />
              {textoCancelaTurno}

            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: 20 }}>
            <Button onClick={handleClose} color="primary">
            No cancelar
          </Button>
            <Button variant="contained" onClick={handleCloseElimina} color="secondary">
            Si quiero cancelar mi turno
          </Button>
          </DialogActions>
        </Dialog>

      </>
    )
  } else {
    return (
      <> <p style={{ margin: 20 }}> No hay turnos reservados</p></>
    )
  }

}