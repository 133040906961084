import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
 

// styles
import useStyles from "../../styles/pages/login/styles";

// logo
import logo from "./Escudo-horizontal_baja.png";
 

// context
import { useUserDispatch, loginUser, registerUser, useUserState  } from "../../context/UserContext";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();
  var { dni, token } = useUserState();

  if (dni) {
    console.log("SALTA***");
    props.history.push('/app/dashboard')
  }

  if(!token){
    localStorage.removeItem("EnRefresh");
  }

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [obsBloqueo, setObsBloqueo] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [dniValue, setDniValue] = useState("");
  var [emailValue, setEmailValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [password2Value, setPassword2Value] = useState("");

  useEffect(function () {
   
    //console.log("en LOGIN: ", error);
    if(error)
    {
      const _bloqueado = localStorage.getItem("bloqueado");
      //console.log("_bloqueado: ", _bloqueado);
      if(_bloqueado)
      {
        //console.log("obsBloqueo: ", localStorage.getItem("obsBloqueo"));
        setObsBloqueo(localStorage.getItem("obsBloqueo"));
      }
    }

  }, [error, obsBloqueo]);

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage}  />
        
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
        <div className={classes.logoMBContainer}>
        <img src={logo} alt="logo" className={classes.logoMBtypeImage} />
        </div>
       
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="ENTRAR" classes={{ root: classes.tab }} />
            <Tab label="REGISTRARSE" classes={{ root: classes.tab }} />
          </Tabs>
       
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography variant="h5" className={classes.greeting}>
                Acceso a la APP
              </Typography>
               
              
              <TextField
                id="dni"
                InputProps={{
                  classes: {
                     
                    input: classes.textField,
                  },
                }}
                value={dniValue}
                onChange={e => setDniValue(e.target.value)}
                margin="normal"
                placeholder="DNI"
                type="number"
                fullWidth
                variant="filled"
                label="DNI"
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                     
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Contraseña"
                type="password"
                fullWidth
                variant="filled"
                label="Contraseña"
              />
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      dniValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        dniValue,
                        
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError, false
                      )
                    }
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                  >
                    Entrar
                  </Button>
                )}
              
              </div>
              <Typography color="primary" style={{ marginTop: 15}} >
                  Si olvidaste tu contraseña, por favor presentarse en mesa de entrada con DNI en vigencia. 
                </Typography>
              <Fade in={error}>
                
                <Typography color="secondary" className={classes.errorMessage}>
                { obsBloqueo ? 
                  <> ACCESO BLOQUEADO<br /> {obsBloqueo}  </>
                : <>   Verifica tu DNI y contraseña  </>
                }
                </Typography>
                 
                 
              </Fade>
            </React.Fragment>
          )}
          {activeTabId === 1 && (
            <React.Fragment>
              <Typography variant="h3" className={classes.greeting}>
                Bienvenido!
              </Typography>
              <Typography variant="h5" className={classes.subGreeting}>
                Crea tu cuenta de acceso
              </Typography>
             
              <TextField
                id="dni"
                InputProps={{
                  classes: {
                     
                    input: classes.textField,
                  },
                }}
                value={dniValue}
                onChange={e => setDniValue(e.target.value)}
                margin="normal"
                placeholder="DNI"
                type="number"
                fullWidth
                variant="filled"
                label="DNI"
              />
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    
                    input: classes.textField,
                  },
                }}
                value={emailValue}
                onChange={e => setEmailValue(e.target.value)}
                margin="normal"
                placeholder="Dirección de Email"
                type="email"
                fullWidth
                variant="filled"
                label="Dirección de Email"
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                     
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Contraseña"
                type="password"
                fullWidth
                variant="filled"
                label="Contraseña"
              />
              <TextField
                id="password2"
                InputProps={{
                  classes: {
                     
                    input: classes.textField,
                  },
                }}
                value={password2Value}
                onChange={e => setPassword2Value(e.target.value)}
                margin="normal"
                placeholder="Repita la contraseña"
                type="password"
                fullWidth
                variant="filled"
                label="Repita la contraseña"
              />
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                    onClick={() =>
                      registerUser(
                        userDispatch,
                        dniValue,
                        emailValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError,
                      )
                    }
                    disabled={
                      dniValue.length < 8 ||
                      passwordValue.length === 0 ||
                      emailValue.length === 0 ||
                      passwordValue !== password2Value
                    }
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    Crear nuevo usuario
                  </Button>
                )}
              </div>
              <Fade in={error}>               
                <Typography color="secondary" className={classes.errorMessage}>
                  Verifica que los datos sean correctos  
                </Typography>
              </Fade>
            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2020 Municipalidad de Santa Rosa de Calamuchita
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
